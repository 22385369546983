import React from 'react'
import { Link } from 'gatsby'

const SnippetCard = ({ snippet }) => (
  <figure
    className="rounded-xl overflow-hidden shadow
  -lg"
  >
    <Link to={snippet.path}>
      <div className="px-6 pt-6 pb-8 text-center">
        <h3 className="font-bold text-xl mb-4 text-primary">{snippet.title}</h3>
        <p className="text-gray-700 text-base">{snippet.subtitle}</p>
      </div>
    </Link>
  </figure>
)

export default SnippetCard
